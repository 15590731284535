var googleMapOptions = {
    nativeOptions: {
        el: '.gmaps',
        zoom: 15,
        draggable: true,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        zoomControl: true
    },

    coords: { lat: 50.8613032, lon: 4.351 },
    setCenterOnResize: true,

    customStyler: [
        {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{
            color: '#2d2727'
        }]
    }, {
        featureType: 'administrative',
        elementType: 'labels.text',
        stylers: [{
            visibility: 'off'
        }]
    }, {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [{
            color: '#444444'
        }]
    }, {
        featureType: 'administrative.country',
        elementType: 'geometry.fill',
        stylers: [{
            visibility: 'simplified'
        }, {
            invert_lightness: true
        }, {
            hue: '#00ffdf'
        }, {
            saturation: '-51'
        }]
    }, {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [{
            color: '#dbdbdb'
        }, {
            visibility: 'on'
        }]
    }, {
        featureType: 'administrative.country',
        elementType: 'labels.text',
        stylers: [{
            visibility: 'off'
        }]
    }, {
        featureType: 'administrative.province',
        elementType: 'geometry.stroke',
        stylers: [{
            visibility: 'off'
        }]
    }, {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [{
            color: '#f2f2f2'
        }]
    }, {
        featureType: 'poi',
        elementType: 'all',
        stylers: [{
            visibility: 'off'
        }]
    }, {
        featureType: 'road',
        elementType: 'all',
        stylers: [{
            saturation: -100
        }, {
            lightness: 45
        }]
    }, {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [{
            visibility: 'simplified'
        }]
    }, {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [{
            visibility: 'off'
        }]
    }, {
        featureType: 'transit',
        elementType: 'all',
        stylers: [{
            visibility: 'off'
        }]
    }, {
        featureType: 'water',
        elementType: 'all',
        stylers: [{
            color: '#c2c9cc'
        }, {
            visibility: 'on'
        }]
    }, {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{
            visibility: 'on'
        }, {
            color: '#f9f9f9'
        }]
    }, {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [{
            visibility: 'off'
        }]
    }]
};

function GoogleMap(options) {
    var nativeOptions = options.nativeOptions;
    var coords = options.coords || false;
    var setCenterOnResize = options.setCenterOnResize && true;
    var customStyler = options.customStyler || false;

    var gmap = {};

    this.generateMap = function generateMap(latitude, longitude) {
        nativeOptions.lat = latitude;
        nativeOptions.lng = longitude;
        gmap = new GMaps(nativeOptions);
        this.applyOptions();
    }.bind(this);

    this.applyOptions = function applyOptions() {
        // custom styles
        if (customStyler.length) {
            gmap.addStyle({
                styles: customStyler,
                mapTypeId: 'mapStyle'
            });
            gmap.setStyle('mapStyle');
        }
        // Set center on resize
        if (setCenterOnResize) {
            window.addEventListener('resize', function resetCenter() {
                gmap.setCenter(coords.lat, coords.lon);
            });
        }

        gmap.addMarker({
            lat: 50.8613032,
            lng: 4.3364077,
            icon: '/assets/images/athos_map_marker.png'
        });
        return this;
    }.bind(this);

    this.generateMap(coords.lat, coords.lon);
}

new GoogleMap(googleMapOptions);
